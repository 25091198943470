import { fetchApi } from "@/utils/axios";
import http from "@/utils/axios";
import URL from "@/api/urlConfig"

const prefix = '/api/dscloud-member-mall'

// 获取详情
export const umbreConfigDetail = () => {
  return fetchApi(prefix + '/points-borrow-umbrella/config/query');
}

// 点击保存
export const umbreConfigSave = (data) => {
  return fetchApi(prefix + '/points-borrow-umbrella/config/save', data, 'POST', 'data')
}

// 更新二维码
export const umbreQrCodeUpdate = (data) => {
  return fetchApi(prefix + '/points-borrow-umbrella/config/qr-code/update', data, 'POST', 'data')
}
