<template>
  <div>
    <a-form-model :model="form" ref="ruleForm" :rules="rules" :label-col="{ span: 7 }" :wrapper-col="{ span: 8 }">
      <a-form-model-item label="借伞方式" prop="borrowMethod">
        <a-radio-group v-model="form.borrowMethod">
          <a-radio :value="0">
            仅积分
          </a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="消耗积分" prop="consumePoints">
        <a-input-number v-model.trim="form.consumePoints" :min="1" :max="999999" :precision="0"/>
      </a-form-model-item>
      <a-form-model-item label="返还积分" prop="returnPoints">
        <a-input-number v-model.trim="form.returnPoints" :min="1" :max="999999" :precision="0"/>
      </a-form-model-item>
      <a-form-model-item label="返还积分有效期" prop="returnValidityPeriod">
        <a-radio-group v-model="form.returnValidityPeriod" @change="changeIntValRadio">
          <a-radio :value="0">无限制</a-radio>
          <a-radio :value="1">
            自借伞后
            <a-input-number v-model.trim="form.returnDaysLimit" :min="1" :max="300" :precision="0" />
            天内
          </a-radio>
        </a-radio-group>
      </a-form-model-item>
      <!-- 自借伞x天内显示 -->
      <a-form-model-item label="返还倒计时提醒时间" prop="returnCountdownReminderTimesJson" v-if="form.returnValidityPeriod == 1">
        <a-checkbox-group v-model="form.returnCountdownReminderTimesJson">
          <a-checkbox :value="'2'" :disabled="form.returnDaysLimit <= 3"> 72小时 </a-checkbox>
          <a-checkbox :value="'1'" :disabled="form.returnDaysLimit <= 2"> 48小时 </a-checkbox>
          <a-checkbox :value="'0'" :disabled="form.returnDaysLimit <= 1"> 24小时 </a-checkbox>
        </a-checkbox-group>
      </a-form-model-item>
      <!-- 无限制显示 -->
      <a-form-model-item label="自借伞后提醒时间" prop="selfBorrowReminderTimesJson" v-if="form.returnValidityPeriod == 0">
        <a-checkbox-group v-model="form.selfBorrowReminderTimesJson">
          <a-checkbox :value="'2'"> 72小时 </a-checkbox>
          <a-checkbox :value="'1'"> 48小时 </a-checkbox>
          <a-checkbox :value="'0'"> 24小时 </a-checkbox>
        </a-checkbox-group>
      </a-form-model-item>
      <a-form-model-item label="支持返还地址" prop="returnAddressSupported">
        <a-textarea v-model.trim="form.returnAddressSupported" placeholder="" :rows="4" />
      </a-form-model-item>
      <a-form-model-item label="活动说明展示图" prop="activityDescriptionImage">
        <a-upload
          :disabled="disable"
          name="file"
          list-type="picture-card"
          :action="IMG_API + '/oss/files'"
          class="avatar-uploader"
          :showUploadList="{ showPreviewIcon: false, showRemoveIcon: true }"
          :file-list="imgList"
          @change="handleChangeImg"
          :before-upload="imgBeforeUpload"
        >
          <img v-if="form.activityDescriptionImage && imgList.length == 0" :src="form.activityDescriptionImage" class="showPicture" />
          <div v-if="!form.activityDescriptionImage && imgList.length < 1">
            <a-icon type="plus" />
            <div class="ant-upload-text">Upload</div>
          </div>
        </a-upload>
        <span style="color:red">支持上传jpg、png格式的图片，大小不超过1M，建议尺寸750*1400【最低高度】</span>
      </a-form-model-item>
      <a-form-model-item label="操作人" prop="operator">
        <div>{{ form.updateId }}</div>
      </a-form-model-item>
      <a-form-model-item label="操作时间" prop="operTime">
        <div>{{ form.updateTime }}</div>
      </a-form-model-item>

      <div class="footer">
        <a-button key="submit" style="margin-right: 30px" type="primary" @click="save">保存 </a-button>
        <!-- 第一次配置为空&未保存时不展示“二维码”操作 -->
        <a-button v-if="isFirst == 0" style="margin-right: 30px" type="primary" @click="clickQrCode">二维码 </a-button>
      </div>
    </a-form-model>

    <!-- 二维码弹窗 -->
    <a-modal v-model="qrCodeTan" title="二维码" :footer="null">
      <div id="qrcode" ref="qrcode"></div>
      <div class="addressWrap">
        <div class="address">
          <div class="name">链接地址：</div>
          <!-- <div class="addressIn">{{ form.qrCodeUrl }}?qrCodeId={{ form.qrCodeId }}</div> -->
          <div class="addressIn">{{ form.qrCodeUrl }}</div>
        </div>
        <div class="copyBtn" @click="copyText()">复制</div>
      </div>
      <div class="qrCodeBtnWrap">
        <a-button style="margin-right: 80px" type="primary" @click="qrCodeRenew">更新二维码 </a-button>
        <a-button type="primary" @click="downLoadCode"> 下载 </a-button>
      </div>
      <div class="qrCodeTip">请谨慎操作【更新二维码】，更新后需及时更换正在使用的借伞二维码</div>
    </a-modal>
  </div>
</template>

<script>
import { umbreConfigDetail, umbreConfigSave, umbreQrCodeUpdate } from '@/api/integralUmbrella';
import { projectName } from '@/api/modularManagement';
import debounce from 'lodash/debounce';
import wangEditor from 'wangeditor';
import { IMG_API } from '@/config';
import QRCode from 'qrcodejs2';
import { getEnterpriseTypes } from '../../../api/common';
export default {
  name: 'checkInRuleManagement',
  watch: {
    'form.returnDaysLimit': {
      handler(newVal, oldVal) {
        this.form.returnCountdownReminderTimesJson = [];
        if (newVal === 3) {
          this.form.returnCountdownReminderTimesJson = this.form.returnCountdownReminderTimesJson.filter((item) => item !== 3);
        } else if (newVal === 2) {
          this.form.returnCountdownReminderTimesJson = this.form.returnCountdownReminderTimesJson.filter((item) => item !== 3);
          this.form.returnCountdownReminderTimesJson = this.form.returnCountdownReminderTimesJson.filter((item) => item !== 2);
        } else {
          // this.form.returnCountdownReminderTimesJson = [];
        }
      },
      // immediate: true,
    },
  },
  data() {
    return {
      IMG_API: IMG_API,
      disable: false,
      imgList: [],
      isFirst: 1, // 第一次配置1 保存过0
      form: {
        id: '', // 借伞配置ID
        qrCodeId: '', // 二维码id，随机数，更新二维码操作变化
        qrCodeUrl: '', // 二维码链接	
        borrowMethod: 0, // 借伞方式
        consumePoints: '', // 消耗积分
        returnPoints: '', // 返还积分
        returnValidityPeriod: 0, // 返还积分有效期，0无限制，1借伞后x天内
        returnDaysLimit: undefined, // 借伞后返还天数限制	
        returnCountdownReminderTimesJson: [], // 返还倒计时提醒时间，0 24小时，1 48小时，2 72小时	
        selfBorrowReminderTimesJson: [], // 自借伞后提醒时间，0 24小时，1 48小时，2 72小时	
        returnAddressSupported: null, // 支持返还地址
        activityDescriptionImage: null, // 活动说明展示图
        createId: null, // 创建人
        createTime: null, // 创建时间	
        updateId: null, // 修改人
        updateTime: null, // 修改时间	
      },
      rules: {
        borrowMethod: [
          { required: true, message: '请填写借伞方式', trigger: 'blur' },
          { required: false, pattern: new RegExp(/^[^\s]*$/, 'g'), message: '禁止输入空格' },
        ],
        consumePoints: [{ required: true, message: '请填写消耗积分', trigger: 'blur' }],
        returnPoints: [{ required: true, message: '请填写返还积分', trigger: 'blur' }],
        returnValidityPeriod: [{ required: true, message: '请填写返还积分有效期', trigger: 'blur' }],
        // returnCountdownReminderTimesJson: [{ required: true, message: '请填写返还倒计时提醒时间', trigger: 'blur' }],
        selfBorrowReminderTimesJson: [{ required: true, message: '请填写自借伞后提醒时间', type: 'array' }],
        returnAddressSupported: [{ required: true, message: '请填写支持返还地址', trigger: 'blur' }],
        activityDescriptionImage: [{ required: true, message: '请填写活动说明展示图' }],
      },
      // 二维码相关
      qrCodeTan: false,
    };
  },
  created() {
    this.umbreConfigDetail();
  },
  mounted() {},
  methods: {
    // 图片上传
    handleChangeImg({ fileList }) {
      this.imgList = fileList;
      console.log(this.imgList)
      if (this.imgList.length > 0 && this.imgList[0].status === 'done') {
        this.form.activityDescriptionImage = fileList[0].response.redirect_uri;
      }
      if (this.imgList.length === 0) {
        this.form.activityDescriptionImage = '';
      }
      console.log(this.imgList)
      console.log(this.form.activityDescriptionImage)
    },
    //上传文件之前校验图片大小
    imgBeforeUpload(file) {
      let index = file.name.lastIndexOf('.');
      let type = file.name.substring(index + 1, file.name.length);
      const isJPG = type === 'png' || type === 'jpg';
      if (!isJPG) {
        this.$message.error('上传图片只能是jpg、png格式!');
        return new Promise(function (resolve, reject) {
          return reject;
        });
      }
      if (file.size / 1024 > 1024) {
        this.$message.error('图片大小不能超过1M!');
        return new Promise(function (resolve, reject) {
          return reject;
        });
      }
      const width = 750; // 获取图片的限制宽度
      const height = 1400; //获取图片的限制高度
      const isSize = new Promise(function (resolve, reject) {
        const URL = window.URL || window.webkitURL;
        const img = new Image();
        img.onload = function () {
          const valid = img.width === width && img.height >= height;
          valid ? resolve() : reject();
        };
        img.src = URL.createObjectURL(file);
      }).then(
        () => {
          return file;
        },
        () => {
          this.$message.error('图片不规范，请按要求上传');
          this.checkSize = false;
          return reject();
        }
      );
      return isJPG && isSize;
    },
    // 获取详情
    async umbreConfigDetail() {
      const res = await umbreConfigDetail();
      if (res.code === '200' && res.data) {
        this.form = JSON.parse(JSON.stringify(res.data))
        if (this.form.returnValidityPeriod == 0){
          this.form.returnDaysLimit = null
        }
        console.log(this.form.returnDaysLimit)
        this.$nextTick(() => {
          this.form.returnCountdownReminderTimesJson = res.data.returnCountdownReminderTimesJson
        })
        this.isFirst = 0
      } else {
        this.isFirst = 1
      }
    },
    // 保存
    save: debounce(function () {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          let obj = this.form
          const res = await umbreConfigSave(obj);
          if (res.code === this.$SUCCESS_CODE) {
            this.$message.success(res.msg);
            this.isFirst = 0
            this.umbreConfigDetail()
          } else {
            this.$message.error(res.msg);
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    }, 500),
    clickQrCode() {
      console.log('点了二维码');
      // this.qrCodeTan = true
      this.genQRCode();
    },
    copyText() {
      // const textToCopy = this.form.qrCodeUrl + '?qrCodeId=' + this.form.qrCodeId;
      const textToCopy = this.form.qrCodeUrl;
      if (textToCopy) {
        let oInput = document.createElement('input')
        oInput.value = textToCopy
        document.body.appendChild(oInput)
        oInput.select() // 选择对象;
        document.execCommand('Copy') // 执行浏览器复制命令
        this.$message.success("复制成功！")
        oInput.remove()
      } else {
        this.$message.error('复制失败');
      }
    },
    // 更新二维码
    qrCodeRenew: debounce(async function () {
      const res = await umbreQrCodeUpdate();
      if (res.code === '200' && res.data) {
        this.form.qrCodeId = res.data.qrCodeId
        this.form.qrCodeUrl = res.data.qrCodeUrl
        this.$forceUpdate()
        this.genQRCode()
        this.$message.success('更新成功');
        this.umbreConfigDetail()
      } else {
        this.$message.error(res.msg);
      }
    }, 500),
    // 生成二维码
    genQRCode() {
      const url = this.form.qrCodeUrl + '?qrCodeId=' + this.form.qrCodeId;
      console.log(url)
      if (!url || url.length <= 0) {
        return;
      }
      this.qrCodeTan = true;
      let code = {
        scanType: 'share',
        scanValue: url,
      };
      setTimeout(() => {
        this.$refs.qrcode.innerHTML = '';
        new QRCode(this.$refs.qrcode, {
          width: 200, // 二维码宽度，单位像素
          height: 200, // 二维码高度，单位像素
          text: code.scanValue, // 生成二维码的链接
          correctLevel: QRCode.CorrectLevel.H,
        });
      }, 100);
    },
    // 下载二维码
    downLoadCode() {
      //找到canvas标签
      const myCanvas = document.getElementById('qrcode').getElementsByTagName('canvas');
      //创建一个a标签节点
      const a = document.createElement('a');
      //设置a标签的href属性（将canvas变成png图片）
      // a.href = myCanvas[0].toDataURL('image/png').replace('image/png', 'image/octet-stream')
      a.href = myCanvas[0].toDataURL('image/jpg').replace('image/jpg', 'image/octet-stream');
      //设置下载文件的名字
      a.download = '二维码.jpg';
      //点击
      a.click();
    },
    // 切换返还积分有效期清除校验
    changeIntValRadio(e) {
      this.$refs.ruleForm.clearValidate('selfBorrowReminderTimesJson')
    }
  },
};
</script>

<style>
.bold {
  font-weight: bold;
}
.footer {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}
#qrcode {
  width: 200px;
  height: 200px;
  display: block;
  margin: 0 auto;
}
.addressWrap {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 30px auto 0;
}
.addressWrap .address {
  display: flex;
}
.addressWrap .name {
  width: 70px;
  flex-shrink: 0;
}
.addressWrap .addressIn {
  max-width: 300px;
  flex-grow: 1;
}
.addressWrap .copyBtn {
  flex-shrink: 0;
  color: #409eff;
  cursor: pointer;
  margin-left: 20px;
}
.qrCodeBtnWrap {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
.qrCodeTip {
  color: red;
  text-align: center;
  margin-top: 15px;
}
.showPicture{
  width: 92px;
  height: 92px;
}
</style>
